import Excuter, { ExcuterEvent, ExcuterProps } from './Excuter';
import Flag, { FlagProps } from './Flag';

interface RunnerProps extends ExcuterProps {
  intervalMs?: number;
}
enum STATUS {
  ready = 'ready',
  running = 'running',
  stop = 'stop',
}
enum IntervalEvent {
  startInterval = 'start-interval',
  start = 'start',
  stop = 'stop',
}
export const RunnerEvent = {...ExcuterEvent, ...IntervalEvent};
type RunnerEvent = IntervalEvent | ExcuterEvent; 


export default class Runner extends Excuter {
  _intervalMs: number;

  _flags: Set<Flag>;

  _status: STATUS;

  _intervalTimer?: NodeJS.Timeout;

  constructor(props: RunnerProps) {
    super(props);
    const { intervalMs = 5 * 60 * 1000 } = props;
    this._intervalMs = intervalMs;
    this._flags = new Set();
    this._status = STATUS.ready;
  }

  get flags() {
    return Array.from(this._flags);
  }

  get status() {
    return this._status;
  }

  get intervalMs() {
    return this._intervalMs;
  }

  set batchMode(value: boolean) {
    if (this._batchMode !== value) {
      this._batchMode = value;
      this.updateInterval();
      this._emitter.emit(RunnerEvent.switchMode, value ? 'batch' : 'each');
    }
  }


  on(event: RunnerEvent , listener: (...args: any[]) => void) {
    this._emitter.on(event, listener);

    return () => {
      this._emitter.off(event, listener);
    };
  }

  once(event: RunnerEvent, listener: (...args: any[]) => void) {
    this._emitter.once(event, listener);

    return () => {
      this._emitter.off(event, listener);
    };
  }

  off(event: RunnerEvent, listener: (...args: any[]) => void) {
    this._emitter.off(event, listener);
  }

  toString() {
    return `<Runner:${this._name}:${this._batchMode ? 'batch' : 'each'}>`;
  }

  updateInterval(ms = this._intervalMs) {
    if (ms <= 0) {
      throw new Error(`${this} intervalMS:${ms} must more than 0! `);
    }

    if (this._status !== STATUS.running) {
      throw new Error(`${this} runner not running ! status:${this._status}!`);
    }

    if (this._intervalTimer) {
      clearInterval(this._intervalTimer);
    }
    this._intervalMs = ms;

    this._intervalTimer = setInterval(() => {
      this._emitter.emit(RunnerEvent.startInterval, ms);
      this.request();
    }, ms);
  }

  async start() {
    if (this._status === STATUS.running) {
      return;
    }

    this._status = STATUS.running;
    await this.request();
    this._emitter.emit(RunnerEvent.start);
    this.updateInterval();
  }

  stop() {
    if (this._intervalTimer) {
      clearInterval(this._intervalTimer);
      this._intervalTimer = undefined;
    }

    if (this._status !== STATUS.stop) {
      this._status = STATUS.stop;
      this._emitter.emit(RunnerEvent.stop);
    }
  }

  request() {
    return this.excute(this.flags);
  }

  createFlag(config: FlagProps): Flag {
    const flag = new Flag(config);
    this._flags.add(flag);
    return flag;
  }

  add(flags: Flag | Flag[]) {
    if (Array.isArray(flags)) {
      flags.forEach(this._add);
    } else {
      this._add(flags);
    }
    return this;
  }

  _add(flag: Flag) {
    if (flag instanceof Flag) {
      this._flags.add(flag);
    }
  }

  remove(flag: Flag) {
    this._flags.delete(flag);
    return this;
  }

  has(flag: Flag) {
    return this._flags.has(flag);
  }

  clean() {
    this.stop();
    this._flags = new Set();
  }
}
