import { EventEmitter } from 'events';
import { nanoid } from 'nanoid';
import { get } from 'lodash';
import Store, { ChangeCallback } from './Store';

type ContextFundamentalType = boolean | number | string;
type EntityContext = Record<string, ContextFundamentalType>;
type Comparator = <T>(oldValue: T, newValue: T) => boolean;
type ContextCreator =  () => Context;
export enum FlagEvent {
  applyStart = 'apply-start',
  applyNotMatch = 'apply-not-match',
  applyCompare = 'apply-compare',
  applySuccess = 'apply-success',
  applyError = 'apply-error',
  applySkip = 'apply-skip',
}
export interface FlagProps {
  key: string;
  schema?: object;
  createContext(): Context;
  name?: string;
  comparator?<T>(oldValue: T, newValue: T): boolean;
}

export type Context = {
  entityID?: number | string;
  entityContext: EntityContext;
};

export type RuntimeContext = {
  result: EvalResult;
  context: Context;
};

export interface EvalResult {
  flagID?: number;
  flagSnapshotID?: number;
  segmentID?: number;
  variantKey?: string;
  variantAttachment?: object;
  evalContext?: Context;
  timestamp?: string;
}

export default class Flag {
  _emitter: EventEmitter;

  _name: string;

  _key: string;

  _store: Store;

  applying?: RuntimeContext;

  createContext: ContextCreator;

  _comparator: Comparator;

  constructor(props: FlagProps) {
    const {
      name,
      key,
      schema,
      createContext,
      comparator = (_: Record<string, any>, __: Record<string, any>) => true,
    } = props;

    this._emitter = new EventEmitter();
    this._name = name || nanoid(4);
    this._key = key;
    this.createContext = createContext;
    this._comparator = comparator;
    this._store = new Store({
      name: this._name,
      schema: schema || {},
    });
  }

  get values() {
    return this._store.store;
  }

  get attachment() {
    return this.applying?.result?.variantAttachment;
  }

  get key() {
    return this._key;
  }

  get name() {
    return this._name;
  }

  get defaults() {
    return this._store.defaults;
  }

  setDefaults(data: any) {
    this._store.setDefaults(data);
  }

  setComparator(comparator: Comparator) {
    this._comparator = comparator;
  }

  setContextCreator(contextCreator: ContextCreator) {
    this.createContext = contextCreator;
  }

  validate(data: any) {
    this._store.validate(data);
  }

  get(key: string) {
    const defaultValue = get(this._store.defaults, key);
    const value = this._store.get(key, defaultValue);
    return value;
  }

  toString() {
    return `<Flager:${this._name}>`;
  }

  on(event: FlagEvent, listener: (...args: any[]) => void) {
    this._emitter.on(event, listener);

    return () => {
      this._emitter.off(event, listener);
    };
  }

  once(event: FlagEvent, listener: (...args: any[]) => void) {
    this._emitter.once(event, listener);

    return () => {
      this._emitter.off(event, listener);
    };
  }

  off(event: FlagEvent, listener: (...args: any[]) => void) {
    this._emitter.off(event, listener);
  }

  onFlagValueChange(key: string, callback: ChangeCallback) {
    return this._store.onDidChange(key, callback);
  }

  onFlagChange(callback: ChangeCallback) {
    return this._store.onDidAnyChange(callback);
  }

  apply(runtimeContext: RuntimeContext) {
    const {
      result: { variantAttachment: value },
    } = runtimeContext;
    this._emitter.emit(FlagEvent.applyStart, runtimeContext);

    try {
      const { store: oldValue } = this._store;
      const shouldApply = this._comparator(oldValue, value);
      this._emitter.emit(FlagEvent.applyCompare, oldValue, value, shouldApply, runtimeContext);
      if (shouldApply) {
        if (value) {
          this._store.store = value;
          this.applying = runtimeContext;
          this._emitter.emit(FlagEvent.applySuccess, runtimeContext);
        } else {
          this._store.store = this._store.defaults;
          this.applying = undefined;
          this._emitter.emit(FlagEvent.applyNotMatch, runtimeContext);
        }
        return;
      }
      this._emitter.emit(FlagEvent.applySkip, runtimeContext);
    } catch (error) {
      this._emitter.emit(FlagEvent.applyError, error, runtimeContext);
    }
  }
}
