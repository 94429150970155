import { nanoid } from 'nanoid';
import axios, { AxiosInstance } from 'axios';
import { EventEmitter } from 'events';
import { uniq } from 'lodash';
import Flag, { EvalResult, Context } from './Flag';

export interface ExcuterProps {
  endpoint: string;
  timeoutMs?: number;
  name?: string;
  batchMode?: boolean;
}

interface BatchEvalResult extends EvalResult {
  evalContext: Context & { flagKey: string };
}

export enum ExcuterEvent {
  flagEvaluationSuccess = 'flag-evaluation-success',
  evaluationError = 'evaluation-error',
  evaluationSuccess = 'evaluation-success',
  batchEvaluationSuccess = 'batch-evaluation-success',
  batchEvaluationError = 'batch-evaluation-error',
  switchMode = 'switch-mode',
}

export default class Excuter {
  _name: string;

  _session: AxiosInstance;

  _endpoint: string;

  _emitter: EventEmitter;

  _batchMode: boolean;

  constructor(props: ExcuterProps) {
    const { endpoint, timeoutMs, name, batchMode = false } = props;
    this._name = name || nanoid(4);
    this._endpoint = endpoint;
    this._emitter = new EventEmitter();
    this._batchMode = batchMode;
    this._session = axios.create({
      baseURL: endpoint,
      timeout: timeoutMs,
    });
  }

  get batchMode() {
    return this._batchMode;
  }

  get endpoint() {
    return this._endpoint;
  }

  get name() {
    return this._name;
  }

  set batchMode(value: boolean) {
    if (this._batchMode !== value) {
      this._batchMode = value;
      this._emitter.emit(ExcuterEvent.switchMode, value ? 'batch' : 'each');
    }
  }

  on(event: ExcuterEvent , listener: (...args: any[]) => void) {
    this._emitter.on(event, listener);

    return () => {
      this._emitter.off(event, listener);
    };
  }

  once(event: ExcuterEvent, listener: (...args: any[]) => void) {
    this._emitter.once(event, listener);

    return () => {
      this._emitter.off(event, listener);
    };
  }

  off(event: ExcuterEvent, listener: (...args: any[]) => void) {
    this._emitter.off(event, listener);
  }

  toString() {
    return `<Excuter:${this._name}:${this._batchMode ? 'batch' : 'each'}>`;
  }

  excute(flags: Flag[]) {
    return this._batchMode ? this._batchEvaluation(flags) : this._eachEvaluation(flags);
  }

  async _eachEvaluation(flags: Flag[]) {
    const evaluationPromises = flags.map(async (flag) => {
      let context;
      try {
        context = flag.createContext();
        const result = await this._session.post<EvalResult>('/evaluation', {
          ...context,
          flagKey: flag.key,
        });
        const runtimeContext = {
          result: result.data,
          context,
        };
        flag.apply(runtimeContext);
        this._emitter.emit(ExcuterEvent.flagEvaluationSuccess, flag, runtimeContext);
      } catch (error) {
        this._emitter.emit(ExcuterEvent.evaluationError, error, flag, context);
      }
    });

    await Promise.all(evaluationPromises);
    this._emitter.emit(ExcuterEvent.evaluationSuccess);
    return flags;
  }

  async _batchEvaluation(flags: Flag[]) {
    let context;
    try {
      const entities: Context[] = [];
      const keys: string[] = [];
      for (const flag of flags) {
        const { entityContext, ...other } = flag.createContext();
        entities.push({
          entityContext: {
            ...entityContext,
            _targetKey: flag.key,
          },
          ...other,
        });
        keys.push(flag.key);
      }
      const context = {
        entities,
        flagKeys: uniq(keys),
      };
      const restuls = await this._session.post<{
        evaluationResults: BatchEvalResult[];
      }>('/evaluation/batch', context);
      restuls.data.evaluationResults.forEach(({ evalContext: context, ...result }) => {
        if (context.flagKey === context.entityContext._targetKey) {
          const runtimeContext = {
            result,
            context,
          };
          const flag = flags.find((f) => f.key === context.flagKey);
          flag?.apply(runtimeContext);
        }
      });

      this._emitter.emit(ExcuterEvent.batchEvaluationSuccess, restuls.data);
    } catch (error) {
      this._emitter.emit(ExcuterEvent.batchEvaluationError, error, context);
    }
    return flags;
  }
}
