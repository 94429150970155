import type { SetOptional, StringKeyOf } from 'type-fest';

import {
  Context,
  Attributes,
  GetAttributes,
  FeatureKey,
  FeatureResultValue,
  GrowthBook,
} from './GrowthBook';

export type BaseKey =
  | 'apiHost'
  | 'clientKey'
  | 'appName'
  | 'appVersion'
  | 'environment'
  | 'attributes'
  | 'refreshInterval'
  | 'ttl'
  | 'mergeFunction'
  | 'onSuccess'
  | 'onError';

export type OmitKey = 'featureKey';

export type BaseContext<
  A extends Attributes,
  V extends FeatureResultValue,
  FK extends FeatureKey,
> = Pick<Context<A, V, FK>, BaseKey>;

export type ItemContext<
  A extends Attributes,
  V extends FeatureResultValue,
  FK extends FeatureKey,
> = Omit<SetOptional<Context<A, V, FK>, BaseKey>, OmitKey>;

export type ContextMapping<KV extends KeyOfValue, A extends Attributes> = {
  [FK in StringKeyOf<KV>]: ItemContext<A, KV[FK], FK>;
};

export type KeyOfValue<
  K extends string = string,
  V extends FeatureResultValue = FeatureResultValue,
> = Record<K, V>;

export class GrowthBookFactory<KV extends KeyOfValue, A extends Attributes> {
  protected baseContext: BaseContext<A, KV[StringKeyOf<KV>], StringKeyOf<KV>>;

  protected contextMapping: ContextMapping<KV, A>;

  protected growthBookMap = new Map<
    StringKeyOf<KV>,
    GrowthBook<KV[StringKeyOf<KV>], A, StringKeyOf<KV>>
  >();

  protected initialized = false;

  constructor(
    baseContext: BaseContext<A, KV[StringKeyOf<KV>], StringKeyOf<KV>>,
    contextMapping: ContextMapping<KV, A>,
  ) {
    this.baseContext = baseContext;

    this.contextMapping = contextMapping;

    this.initialize();
  }

  protected initialize() {
    const { baseContext, contextMapping, growthBookMap, initialized } = this;

    if (initialized) {
      return growthBookMap;
    }

    const keyContextEntries = Object.entries(contextMapping) as [
      StringKeyOf<KV>,
      ItemContext<A, KV[StringKeyOf<KV>], StringKeyOf<KV>>,
    ][];

    for (const [featureKey, itemContext] of keyContextEntries) {
      const growthBook = new GrowthBook<KV[StringKeyOf<KV>], A, StringKeyOf<KV>>({
        ...baseContext,
        ...itemContext,
        featureKey,
      });

      growthBookMap.set(featureKey, growthBook);
    }

    this.initialized = true;

    return growthBookMap;
  }

  setAttributes(attributes: A | GetAttributes<A>) {
    for (const growthBook of this.growthBookMap.values()) {
      growthBook.setAttributes(attributes);
    }
  }

  setRefreshInterval(refreshInterval?: number) {
    for (const growthBook of this.growthBookMap.values()) {
      growthBook.setRefreshInterval(refreshInterval);
    }
  }

  setTTL(ttl?: number) {
    for (const growthBook of this.growthBookMap.values()) {
      growthBook.setTTL(ttl);
    }
  }

  async start() {
    const promises = [];

    for (const growthBook of this.growthBookMap.values()) {
      promises.push(growthBook.start());
    }

    return Promise.all(promises);
  }

  stop() {
    for (const growthBook of this.growthBookMap.values()) {
      growthBook.stop();
    }
  }

  getGrowthBook<FK extends StringKeyOf<KV>>(featureKey: FK): GrowthBook<KV[FK], A, FK> {
    const growthBook = this.growthBookMap.get(featureKey);

    if (growthBook) {
      return growthBook as unknown as GrowthBook<KV[FK], A, FK>;
    }

    throw new TypeError(`No GrowthBook instance with featureKey: ${featureKey} was found.`);
  }

  each<FK extends StringKeyOf<KV>>(
    callbackFn: (featureKey: FK, growthBook: GrowthBook<KV[FK], A, FK>) => void,
  ) {
    for (const [featureKey, growthBook] of this.growthBookMap.entries()) {
      callbackFn(featureKey as FK, growthBook as unknown as GrowthBook<KV[FK], A, FK>);
    }
  }
}
