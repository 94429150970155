import axios, { AxiosInstance } from 'axios';
import Flag, { FlagProps, EvalResult } from './Flag';

interface SingletonFlagProps extends FlagProps {
  endpoint: string;
  timeoutMs?: number;
}

export enum SingletonFlagEvent {
  requestStart = 'request-start',
  requestSuccess = 'request-success',
  requestError = 'request-error',
  requestConflict = 'request-conflict',
}

export default class SingletonFlag extends Flag {
  _session: AxiosInstance;

  _isRequesting: boolean;

  _endpoint: string;

  constructor(props: SingletonFlagProps) {
    super(props);
    const { endpoint, timeoutMs } = props;
    this._endpoint = endpoint;
    this._isRequesting = false;
    this._session = axios.create({
      baseURL: endpoint,
      timeout: timeoutMs,
    });
  }

  get endpoint() {
    return this._endpoint;
  }

  get requesting() {
    return this._isRequesting
  }

  toString() {
    return `<SingletonFlag:${this._name}>`;
  }

  async requestAndApply() {
    if (this._isRequesting) {
      this._emitter.emit(SingletonFlagEvent.requestConflict);
      return;
    }
    let context;
    this._isRequesting = true;
    try {
      context = this.createContext();
      this._emitter.emit(SingletonFlagEvent.requestStart, context);
      const result = await this._session.post<EvalResult>('/evaluation', {
        ...context,
        flagKey: this.key,
      });
      const runtimeContext = {
        result: result.data,
        context,
      };
      this.apply(runtimeContext);
      this._emitter.emit(SingletonFlagEvent.requestSuccess, runtimeContext);
    } catch (error) {
      this._emitter.emit(SingletonFlagEvent.requestError, error, context);
    }
    this._isRequesting = false;
  }
}
